<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("journeys") }}</h1>
      <br>
      <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
      </div>
      </div>
      <div class="clearfix">

<div  v-if="activePrompt1"  class="vx-row">
  <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
  <p>{{ $t("purpose") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
    v-validate="'required'"
    data-vv-validate-on="blur"
    name="purpose"
    v-model="purpose"
    class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('purpose')">{{ errors.first("purpose") }}</span>
  </div>
  
  <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
   <p>{{ $t("mission") }}<b style="color: #ff6141" >*</b> </p>
   <vs-input
  disabled
  name="mission_name"
  v-model="mission_name"
  class="w-full"/>
 
  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
  <p>{{ $t("departurePlace") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
      v-validate="'required'"
      v-model="departurePlace"
      data-vv-validate-on="blur"
      name="departurePlace"
      class="w-full"
      />
  <span class="text-danger text-sm" v-show="errors.has('departurePlace')">{{ errors.first("departurePlace") }}</span>
  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
  <p>{{ $t("departurePlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
      @blur="validateCoordinates_longitude1"
      :min="0" 
      type="number"
      v-validate="'required'"
      v-model="departurePlaceLongitude"
      data-vv-validate-on="blur"
      name="departurePlaceLongitude"
      class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('departurePlaceLongitude')">{{ errors.first("departurePlaceLongitude") }}</span>
  <br v-if="!isValid_longitude1">
  <span class="text-danger text-sm" v-if="!isValid_longitude1" >Longitude invalide.</span>

  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
  <p>{{ $t("departurePlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
      @blur="validateCoordinates_latitude1"
      :min="0" 
      type="number"
      v-validate="'required'"
      v-model="departurePlaceLatitude"
      data-vv-validate-on="blur"
      name="departurePlaceLatitude"
      class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('departurePlaceLatitude')">{{ errors.first("departurePlaceLatitude") }}</span>
  <br v-if="!isValid_latitude1">
  <span class="text-danger text-sm" v-if="!isValid_latitude1" >Latitude invalide.</span>
  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
  <p>{{ $t("arrivalPlace") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
      v-validate="'required'"
      v-model="arrivalPlace"
      data-vv-validate-on="blur"
      autocomplete
      name="arrivalPlace"
      class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('arrivalPlace')">{{ errors.first("arrivalPlace") }}</span>

  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
  <p>{{ $t("arrivalPlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
     @blur="validateCoordinates_longitude2"
      :min="0" 
      type="number"
      v-validate="'required'"
      v-model="arrivalPlaceLongitude"
      data-vv-validate-on="blur"
      name="arrivalPlaceLongitude"
      class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLongitude')">{{ errors.first("arrivalPlaceLongitude") }}</span>
  <br v-if="!isValid_longitude2">
  <span class="text-danger text-sm" v-if="!isValid_longitude2" >Longitude invalide.</span>
  </div>

  <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
  <p>{{ $t("arrivalPlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
  <vs-input
      @blur="validateCoordinates_latitude2"
      :min="0" 
      type="number"
      v-validate="'required'"
      v-model="arrivalPlaceLatitude"
      data-vv-validate-on="blur"
      name="arrivalPlaceLatitude"
      class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLatitude')">{{ errors.first("arrivalPlaceLatitude") }}</span>
  <br v-if="!isValid_latitude2">
  <span class="text-danger text-sm" v-if="!isValid_latitude2" >Latitude invalide.</span>
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>{{ $t("departureTime") }}<b style="color: #ff6141" >*</b></p>
  <flat-pickr 
   :config="configdateTimePicker"
   v-model="departureTime"
   v-validate="'required'"
   data-vv-validate-on="blur"
   autocomplete
   name="departureTime"
   class="w-full" 
    />
  <span class="text-danger text-sm" v-show="errors.has('departureTime')">{{ errors.first("departureTime") }}</span>
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>{{ $t("duree") }}<b style="color: #ff6141" >*</b></p>
  <flat-pickr 
   :config="configdateTimePicker1"
   v-model="duree"
   v-validate="'required'"
   data-vv-validate-on="blur"
   autocomplete
   name="duree"
   class="w-full" 
  />
  <span class="text-danger text-sm" v-show="errors.has('duree')">{{ errors.first("duree") }}</span>
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>{{ $t("stopTime") }}<b style="color: #ff6141" >*</b></p>
  <flat-pickr 
   disabled
   :config="configdateTimePicker"
   v-model="stopTime"
   v-validate="'required'"
   data-vv-validate-on="blur"
   autocomplete
   name="stopTime"
   class="w-full" 
  />
  <span class="text-danger text-sm" v-show="errors.has('stopTime')">{{ errors.first("stopTime") }}</span>
  </div>
  
  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>{{ $t("numberOfBreak") }}<b style="color: #ff6141" >*</b></p>
  <vs-input-number
  disabled
  :min="0" 
  v-validate="'required'"
  data-vv-validate-on="blur"
  name="numberOfBreak"
  v-model="numberOfBreak"
  class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('numberOfBreak')">{{ errors.first("numberOfBreak") }}</span>
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>{{ $t("distance") }}{{' '}}(Km)<b style="color: #ff6141" >*</b></p>
  <vs-input-number
  :min="0" 
  v-validate="'required'"
  data-vv-validate-on="blur"
  name="distance"
  v-model="distance"
  class="w-full"
  />
  <span class="text-danger text-sm" v-show="errors.has('distance')">{{ errors.first("distance") }}</span>
  </div>
  
  <div hidden class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <p>Avez-vous de(s) pause(s) à faire ?<b style="color: #ff6141" >*</b></p>
  <vs-checkbox 
  v-validate="'required'"
  v-model="pause"
  name="pause"
  class="w-full">
  </vs-checkbox>
  <span class="text-danger text-sm" v-show="errors.has('pause')">{{ errors.first("pause") }}</span>
  </div>

  <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
  <label class="text-sm opacity-75">{{ $t("interventionPlan") }}<b style="color: #ff6141" >*</b></label>
  <vs-textarea
  name="interventionPlan"
  v-validate="'required'"
  v-model="interventionPlan"
  rows="3"
  class="w-full" />
  <span class="text-danger text-sm" v-show="errors.has('interventionPlan')">{{ errors.first("interventionPlan") }}</span>
  </div>

  <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
  <label class="text-sm opacity-75">{{ $t("interventionPlanContact") }}<b style="color: #ff6141" >*</b></label>
  <vue-tel-input
    v-model="interventionPlanContact"
    placeholder="Telephone"
    :mode="national"
    :autoFormat="false"
    :enabledCountryCode="true"
    :enabledFlags="true"
    :validCharactersOnly="true"
    data-vv-validate-on="blur"
    v-validate="'required|min:8|max:8'"
    @validate="yourValidationMethod"
    name="interventionPlanContact"
    class="w-full"
  >
  </vue-tel-input>
  <span class="text-danger text-sm" v-show="errors.has('interventionPlanContact')">{{ errors.first("interventionPlanContact") }}</span>
  <div class="vx-col p-6 sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
    <label class="text-sm opacity-75">{{'Ajoutez un fichier kmz'}}<b style="color: #ff6141" >*</b></label>
      <p><span><b style="color:orange">{{ name_file1_3 }}</b></span></p>
      <input v-validate="'required'" name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".kmz,.kml" >
      <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
      <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{ errors.first("name_file1_3") }}</span>

    </div>
  </div>

  <vx-card v-if="pause === 1 " class="mt-10" no-shadow card-border>
  <div class="vx-row ">
  <div class="vx-col w-full">
  <div class="flex items-end px-3">
    <feather-icon svgClasses="w-6 h-6" icon="PauseIcon" class="mr-2" />
    <span class="font-medium text-lg leading-none">Définissez la pause </span>
  </div>
  <br/>
  <b style="color: #ff6141" >{{'Il vous reste'}}{{'  '}}{{this.New_hours_required}}{{ '  ' }}{{ 'pause(s) obligatoires à planifier'}}</b>
  <vs-divider />
  </div>
  </div>
  <div class="vx-row ">
  </div>
  <div class="vx-row ">
  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("site") }}<b style="color: #ff6141" >*</b></label>
  <vs-input
        v-model="sites_tab"
        autocomplete
        name="sites"
        class="w-full"
  />
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("longitude") }}<b style="color: #ff6141" >*</b></label>
    <vs-input
        @blur="validateCoordinates_longitude3"
        :min="0" 
        type="number"
        v-model="longitudes_tab"
        name="longitudes"
        class="w-full"
    />
    <br v-if="!isValid_longitude3">
    <span class="text-danger text-sm" v-if="!isValid_longitude3" >Longitude invalide.</span>
  </div>

  <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("latitude") }}<b style="color: #ff6141" >*</b></label>
    <vs-input
        @blur="validateCoordinates_latitude3"
        :min="0" 
        type="number"
        v-model="latitudes_tab"
        name="latitudes"
        class="w-full"
    />
    <br v-if="!isValid_latitude3">
    <span class="text-danger text-sm" v-if="!isValid_latitude3" >Latitude invalide.</span>
  </div>

  <div  class="vx-col  sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("breakStartTimes") }}<b style="color: #ff6141" >*</b></label>
  <v-select
    name="breakStartTimes"
    :reduce="rep => rep.hours"
    class="w-full" 
    label="name"
    v-model="breakStartTimes_tab"
    :options="New_hours">
  </v-select>
  <span class="text-danger text-sm pt-2" v-if="heure_check">L'heure définie n'est pas autorisée</span>

  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("details") }}<b style="color: #ff6141" >*</b></label>
  <vs-textarea
  name="details"
  v-model="details_tab"
  rows="3"
  class="w-full" />

  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("restriction") }}<b style="color: #ff6141" >*</b></label>
  <vs-textarea
  v-model="restrictions_tab"
  name="restrictions"
  rows="3"
  class="w-full" />
  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("applicator") }}<b style="color: #ff6141" >*</b></label>
  <vs-select 
  :closeOnSelect="false"
  v-model="applicators_tab"
  autocomplete
  name="applicators"
  class="w-full">
  <vs-select-item :key="item1" :value="item1" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("controller") }}<b style="color: #ff6141" >*</b></label>
  <vs-select 
  :closeOnSelect="false"
  v-model="controllers_tab"
  autocomplete
  name="controllers"
  class="w-full">
  <vs-select-item :key="item2" :value="item2" :text="item2.fullName" v-for="item2 in staffs" /></vs-select>
  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("measure") }}<b style="color: #ff6141" >*</b></label>
  <vs-select 
  :closeOnSelect="false"
  v-model="measures_tab"
  autocomplete
  name="measures"
  class="w-full">
  <vs-select-item :key="item3" :value="item3" :text="item3.measure" v-for="item3 in recommendedSefetyMeasures" /></vs-select>
  </div>

  <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
  <label class="text-sm opacity-75">{{ $t("readAndApproved") }}<b style="color: #ff6141" >*</b></label>

  <vs-checkbox 
      v-model="readAndApproved_tab"
      name="readAndApproved"
      class="w-full">
    </vs-checkbox>
  </div>

  <div class="vx-col md:w-full sm:w-full w-full ">
  <div class="flex flex-wrap justify-between mt-5 ">
      <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>

      <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
      
      <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

    </div>
  </div>
  </div>
  <div class="vx-row mt-4">
  <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
  <template slot="header">
  <h3>
  Pause(s)
  </h3>
  </template>
  <template slot="thead">
  <vs-th >N°</vs-th>
  <vs-th>{{ $t("sites") }}</vs-th>
  <vs-th>{{ $t("longitudes") }}</vs-th>
  <vs-th>{{ $t("latitudes") }}</vs-th>
  <vs-th>{{ $t("breakStartTimes") }}</vs-th>
  <vs-th>{{ $t("details") }}</vs-th>
  <vs-th>{{ $t("restriction") }}</vs-th>
  <vs-th>{{ $t("applicator") }}</vs-th>
  <vs-th>{{ $t("controller") }}</vs-th>
  <vs-th>{{ $t("measure") }}</vs-th>
  <vs-th>{{ $t("readAndApproved") }}</vs-th>

  <vs-th>Actions</vs-th>
  </template>

  <template slot-scope="{data}">
  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

  <vs-td :data="data[indextr].id">
    {{data[indextr].id}}
  </vs-td>
  <vs-td :data="data[indextr].sites">
    {{data[indextr].sites}}
  </vs-td>
  <vs-td :data="data[indextr].longitudes">
    {{data[indextr].longitudes}}
  </vs-td>
  <vs-td :data="data[indextr].latitudes">
    {{data[indextr].latitudes}}
  </vs-td>
  <vs-td :data="data[indextr].breakStartTimes">
    {{data[indextr].breakStartTimes}}
  </vs-td>

  <vs-td :data="data[indextr].details">
    {{data[indextr].details}}
  </vs-td>

  <vs-td :data="data[indextr].restrictions">
    {{data[indextr].restrictions}}
  </vs-td>

  <vs-td :data="data[indextr].applicators">
    {{data[indextr].applicators.fullName}}
  </vs-td>
  <vs-td :data="data[indextr].controllers">
    {{data[indextr].controllers.fullName}}
  </vs-td>
  <vs-td :data="data[indextr].measures">
    {{data[indextr].measures.measure}}
  </vs-td>
  <vs-td :data="data[indextr].readAndApproved">
    {{ApprouveObject[data[indextr].readAndApproved]}}
  </vs-td>
  <vs-td class="whitespace-no-wrap">
    <div class="flex">
      <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
      
      <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>

    </div>
  </vs-td>
  </vs-tr>
  </template>
  </vs-table>
  </div>

  </vx-card>
  <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>

</div>
</div>

    <div  v-if="activePrompt2"  class="clearfix">
              <div class="vx-row">
                <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                <p>{{ $t("purpose") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="purpose"
                  v-model="purpose"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('purpose')">{{ errors.first("purpose") }}</span>
                </div>
                
                <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                  <p>{{ $t("mission") }}<b style="color: #ff6141" >*</b> </p>
                  <vs-input
                  disabled
                  name="mission_name"
                  v-model="mission_name"
                  class="w-full"/>
                
                </div>
        
                <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                <p>{{ $t("departurePlace") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                    v-validate="'required'"
                    v-model="departurePlace"
                    data-vv-validate-on="blur"
                    name="departurePlace"
                    class="w-full"
                    />
                <span class="text-danger text-sm" v-show="errors.has('departurePlace')">{{ errors.first("departurePlace") }}</span>
                </div>

                <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
                <p>{{ $t("departurePlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                    @blur="validateCoordinates_longitude1"
                    :min="0" 
                    type="number"
                    v-validate="'required'"
                    v-model="departurePlaceLongitude"
                    data-vv-validate-on="blur"
                    name="departurePlaceLongitude"
                    class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('departurePlaceLongitude')">{{ errors.first("departurePlaceLongitude") }}</span>
                <br v-if="!isValid_longitude1">
                <span class="text-danger text-sm" v-if="!isValid_longitude1" >Longitude invalide.</span>

                </div>

                <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                <p>{{ $t("departurePlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                    @blur="validateCoordinates_latitude1"
                    :min="0" 
                    type="number"
                    v-validate="'required'"
                    v-model="departurePlaceLatitude"
                    data-vv-validate-on="blur"
                    name="departurePlaceLatitude"
                    class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('departurePlaceLatitude')">{{ errors.first("departurePlaceLatitude") }}</span>
                <br v-if="!isValid_latitude1">
                <span class="text-danger text-sm" v-if="!isValid_latitude1" >Latitude invalide.</span>
                </div>

                <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                <p>{{ $t("arrivalPlace") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                    v-validate="'required'"
                    v-model="arrivalPlace"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="arrivalPlace"
                    class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('arrivalPlace')">{{ errors.first("arrivalPlace") }}</span>

                </div>

                <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
                  <p>{{ $t("arrivalPlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
                  <vs-input
                  @blur="validateCoordinates_longitude2"
                    :min="0" 
                    type="number"
                    v-validate="'required'"
                    v-model="arrivalPlaceLongitude"
                    data-vv-validate-on="blur"
                    name="arrivalPlaceLongitude"
                    class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLongitude')">{{ errors.first("arrivalPlaceLongitude") }}</span>
                <br v-if="!isValid_longitude2">
                <span class="text-danger text-sm" v-if="!isValid_longitude2" >Longitude invalide.</span>
                </div>

                <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
                <p>{{ $t("arrivalPlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
                <vs-input
                    @blur="validateCoordinates_latitude2"
                    :min="0" 
                    type="number"
                    v-validate="'required'"
                    v-model="arrivalPlaceLatitude"
                    data-vv-validate-on="blur"
                    name="arrivalPlaceLatitude"
                    class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLatitude')">{{ errors.first("arrivalPlaceLatitude") }}</span>
                <br v-if="!isValid_latitude2">
                <span class="text-danger text-sm" v-if="!isValid_latitude2" >Latitude invalide.</span>
                </div>

                <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>{{ $t("departureTime") }}<b style="color: #ff6141" >*</b></p>
                <flat-pickr 
                :config="configdateTimePicker"
                v-model="departureTime"
                v-validate="'required'"
                data-vv-validate-on="blur"
                autocomplete
                name="departureTime"
                class="w-full" 
                  />
                <span class="text-danger text-sm" v-show="errors.has('departureTime')">{{ errors.first("departureTime") }}</span>
                </div>

                <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>{{ $t("duree") }}<b style="color: #ff6141" >*</b></p>
                <flat-pickr 
                :config="configdateTimePicker1"
                v-model="duree"
                v-validate="'required'"
                data-vv-validate-on="blur"
                autocomplete
                name="duree"
                class="w-full" 
                />
                <span class="text-danger text-sm" v-show="errors.has('duree')">{{ errors.first("duree") }}</span>
                </div>

                <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>{{ $t("stopTime") }}<b style="color: #ff6141" >*</b></p>
                <flat-pickr 
                disabled
                :config="configdateTimePicker"
                v-model="stopTime"
                v-validate="'required'"
                data-vv-validate-on="blur"
                autocomplete
                name="stopTime"
                class="w-full" 
                />
                <span class="text-danger text-sm" v-show="errors.has('stopTime')">{{ errors.first("stopTime") }}</span>
                </div>
                
                <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>{{ $t("numberOfBreak") }}<b style="color: #ff6141" >*</b></p>
                <vs-input-number
                disabled
                :min="0" 
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="numberOfBreak"
                v-model="numberOfBreak"
                class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('numberOfBreak')">{{ errors.first("numberOfBreak") }}</span>
                </div>

                <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>{{ $t("distance") }}{{' '}}(Km)<b style="color: #ff6141" >*</b></p>
                <vs-input-number
                :min="0" 
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="distance"
                v-model="distance"
                class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('distance')">{{ errors.first("distance") }}</span>
                </div>
                
                <div hidden class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
                <p>Avez-vous de(s) pause(s) à faire ?<b style="color: #ff6141" >*</b></p>
                <vs-checkbox 
                v-validate="'required'"
                v-model="pause"
                name="pause"
                class="w-full">
                </vs-checkbox>
                <span class="text-danger text-sm" v-show="errors.has('pause')">{{ errors.first("pause") }}</span>
                </div>

                <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
                <label class="text-sm opacity-75">{{ $t("interventionPlan") }}<b style="color: #ff6141" >*</b></label>
                <vs-textarea
                name="interventionPlan"
                v-validate="'required'"
                v-model="interventionPlan"
                rows="3"
                class="w-full" />
                <span class="text-danger text-sm" v-show="errors.has('interventionPlan')">{{ errors.first("interventionPlan") }}</span>
                </div>

                <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
                <label class="text-sm opacity-75">{{ $t("interventionPlanContact") }}<b style="color: #ff6141" >*</b></label>
                <vue-tel-input
                  v-model="interventionPlanContact"
                  placeholder="Telephone"
                  :mode="national"
                  :autoFormat="false"
                  :enabledCountryCode="true"
                  :enabledFlags="true"
                  :validCharactersOnly="true"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:8|max:8'"
                  @validate="yourValidationMethod"
                  name="interventionPlanContact"
                  class="w-full"
                >
                </vue-tel-input>
                <span class="text-danger text-sm" v-show="errors.has('interventionPlanContact')">{{ errors.first("interventionPlanContact") }}</span>
   
                <div class="vx-col p-6 sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                  <label class="text-sm opacity-75">{{'Ajoutez un fichier kmz'}}<b style="color: #ff6141" >*</b></label>
                  <p><span><b style="color:orange">{{ name_file1_3 }}</b></span></p>
                  <input  name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".kmz,.kml" >
                  <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
                </div>
              
              </div>

        <vx-card v-if="pause === 1 " class="mt-10" no-shadow card-border>
          <div class="vx-row ">
            <div class="vx-col w-full">
              <div class="flex items-end px-3">
                <feather-icon svgClasses="w-6 h-6" icon="PauseIcon" class="mr-2" />
                <span class="font-medium text-lg leading-none">Définissez la pause </span>
              </div>
              <br/>
            <b v-if="!Check_update" style="color: #ff6141" >MOMO{{'Il vous reste'}}{{'  '}}{{this.New_hours_required}}{{ '  ' }}{{ 'pause(s) obligatoires à planifier'}}</b>
            <b v-if="Check_update" style="color: #ff6141" >{{'Il vous reste'}}{{'  '}}{{this.New_hours_required_update}}{{ '  ' }}{{ 'pause(s) obligatoires à planifier'}}</b>

            <vs-divider />
          </div>
          </div>
          <div class="vx-row ">
        </div>
          <div class="vx-row ">
          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("site") }}<b style="color: #ff6141" >*</b></label>
            <vs-input
                    v-model="sites_tab"
                    autocomplete
                    name="sites"
                    class="w-full"
            />
          </div>

          <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("longitude") }}<b style="color: #ff6141" >*</b></label>
            <vs-input
                    @blur="validateCoordinates_longitude3"
                    :min="0" 
                    type="number"
                    v-model="longitudes_tab"
                    name="longitudes"
                    class="w-full"
                />
                <br v-if="!isValid_longitude3">
                <span class="text-danger text-sm" v-if="!isValid_longitude3" >Longitude invalide.</span>
            </div>

            <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("latitude") }}<b style="color: #ff6141" >*</b></label>
            <vs-input
                    @blur="validateCoordinates_latitude3"
                    :min="0" 
                    type="number"
                    v-model="latitudes_tab"
                    name="latitudes"
                    class="w-full"
                />
                <br v-if="!isValid_latitude3">
                <span class="text-danger text-sm" v-if="!isValid_latitude3" >Latitude invalide.</span>
            </div>

          <div  class="vx-col  sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("breakStartTimes") }}<b style="color: #ff6141" >*</b></label>
            <v-select
                name="breakStartTimes"
                :reduce="rep => rep.hours"
                class="w-full" 
                label="name"
                v-model="breakStartTimes_tab"
                :options="New_hours">
              </v-select>
            <span class="text-danger text-sm pt-2" v-if="heure_check">L'heure définie n'est pas autorisée</span>

          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("details") }}<b style="color: #ff6141" >*</b></label>
            <vs-textarea
            name="details"
            v-model="details_tab"
            rows="3"
            class="w-full" />

          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("restriction") }}<b style="color: #ff6141" >*</b></label>
            <vs-textarea
            v-model="restrictions_tab"
            name="restrictions"
            rows="3"
            class="w-full" />
          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("applicator") }}<b style="color: #ff6141" >*</b></label>
            <vs-select 
            :closeOnSelect="false"
            v-model="applicators_tab"
            autocomplete
            name="applicators"
            class="w-full">
            <vs-select-item :key="item1" :value="item1" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("controller") }}<b style="color: #ff6141" >*</b></label>
            <vs-select 
            :closeOnSelect="false"
            v-model="controllers_tab"
            autocomplete
            name="controllers"
            class="w-full">
            <vs-select-item :key="item2" :value="item2" :text="item2.fullName" v-for="item2 in staffs" /></vs-select>
          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("measure") }}<b style="color: #ff6141" >*</b></label>
            <vs-select 
            :closeOnSelect="false"
            v-model="measures_tab"
            autocomplete
            name="measures"
            class="w-full">
            <vs-select-item :key="item3" :value="item3" :text="item3.measure" v-for="item3 in recommendedSefetyMeasures" /></vs-select>
          </div>

          <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
            <label class="text-sm opacity-75">{{ $t("readAndApproved") }}<b style="color: #ff6141" >*</b></label>
            
            <vs-checkbox 
                  v-model="readAndApproved_tab"
                  name="readAndApproved"
                  class="w-full">
                </vs-checkbox>
          </div>

          <div class="vx-col md:w-full sm:w-full w-full ">
              <div class="flex flex-wrap justify-between mt-5 ">
                  <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>

                  <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
                  
                  <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

                </div>
          </div>
        </div>
        <div class="vx-row mt-4 ">
          <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
          <template slot="header">
            <h3>
            Pause(s)
            </h3>
          </template>
          <template slot="thead">
            <vs-th >N°</vs-th>
            <vs-th>{{ $t("sites") }}</vs-th>
            <vs-th>{{ $t("longitudes") }}</vs-th>
            <vs-th>{{ $t("latitudes") }}</vs-th>
            <vs-th>{{ $t("breakStartTimes") }}</vs-th>
            <vs-th>{{ $t("details") }}</vs-th>
            <vs-th>{{ $t("restriction") }}</vs-th>
            <vs-th>{{ $t("applicator") }}</vs-th>
            <vs-th>{{ $t("controller") }}</vs-th>
            <vs-th>{{ $t("measure") }}</vs-th>
            <vs-th>{{ $t("readAndApproved") }}</vs-th>

            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            
              <vs-td  :data="data[indextr].id">
                {{data[indextr].id}}
              </vs-td>
              <vs-td :data="data[indextr].sites">
                {{data[indextr].sites}}
              </vs-td>
              <vs-td :data="data[indextr].longitudes">
                {{data[indextr].longitudes}}
              </vs-td>
              <vs-td :data="data[indextr].latitudes">
                {{data[indextr].latitudes}}
              </vs-td>
              <vs-td :data="data[indextr].breakStartTimes">
                {{data[indextr].breakStartTimes}}
              </vs-td>
              
              <vs-td :data="data[indextr].details">
                {{data[indextr].details}}
              </vs-td>

              <vs-td :data="data[indextr].restrictions">
                {{data[indextr].restrictions}}
              </vs-td>

              <vs-td :data="data[indextr].applicators">
                {{data[indextr].applicators.fullName}}
              </vs-td>
              <vs-td :data="data[indextr].controllers">
                {{data[indextr].controllers.fullName}}
              </vs-td>
              <vs-td :data="data[indextr].measures">
                {{data[indextr].measures.measure}}
              </vs-td>
              <vs-td :data="data[indextr].readAndApproved">
                {{ApprouveObject[data[indextr].readAndApproved]}}
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <div class="flex">
                  <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
                  
                  <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
              
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        </div>
    
          </vx-card>
            </div>
              <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
    </div>
      
    <div  v-if="activePrompt3"  class="clearfix">

      <div class="vx-row">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
      <p>{{ $t("purpose") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
      disabled
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="purpose"
      v-model="purpose"
      class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('purpose')">{{ errors.first("purpose") }}</span>
      </div>

      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
      <p>{{ $t("mission") }}<b style="color: #ff6141" >*</b> </p>
      <vs-input
      disabled
      name="mission_name"
      v-model="mission_name"
      class="w-full"/>

      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("departurePlace") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        disabled
        v-validate="'required'"
        v-model="departurePlace"
        data-vv-validate-on="blur"
        name="departurePlace"
        class="w-full"
        />
      <span class="text-danger text-sm" v-show="errors.has('departurePlace')">{{ errors.first("departurePlace") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
      <p>{{ $t("departurePlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        disabled
        type="number"
        :min="0" 
        v-validate="'required'"
        v-model="departurePlaceLongitude"
        data-vv-validate-on="blur"
        name="departurePlaceLongitude"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('departurePlaceLongitude')">{{ errors.first("departurePlaceLongitude") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("departurePlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        type="number"
        disabled
        :min="0" 
        v-validate="'required'"
        v-model="departurePlaceLatitude"
        data-vv-validate-on="blur"
        name="departurePlaceLatitude"
        class="w-full"
      />

      <span class="text-danger text-sm" v-show="errors.has('departurePlaceLatitude')">{{ errors.first("departurePlaceLatitude") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlace") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        disabled
        v-validate="'required'"
        v-model="arrivalPlace"
        data-vv-validate-on="blur"
        autocomplete
        name="arrivalPlace"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('arrivalPlace')">{{ errors.first("arrivalPlace") }}</span>

      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          type="number"
          disabled
        :min="0" 
        v-validate="'required'"
        v-model="arrivalPlaceLongitude"
        data-vv-validate-on="blur"
        name="arrivalPlaceLongitude"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLongitude')">{{ errors.first("arrivalPlaceLongitude") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          type="number"
          disabled
        :min="0" 
        v-validate="'required'"
        v-model="arrivalPlaceLatitude"
        data-vv-validate-on="blur"
        name="arrivalPlaceLatitude"
        class="w-full"
      />

      <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLatitude')">{{ errors.first("arrivalPlaceLatitude") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("departureTime") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      disabled
      :config="configdateTimePicker"
      v-model="departureTime"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="departureTime"
      class="w-full" 
      />
      <span class="text-danger text-sm" v-show="errors.has('departureTime')">{{ errors.first("departureTime") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("duree") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      disabled
      :config="configdateTimePicker1"
      v-model="duree"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="duree"
      class="w-full" 
      />
      <span class="text-danger text-sm" v-show="errors.has('duree')">{{ errors.first("duree") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("stopTime") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      disabled
      :config="configdateTimePicker"
      v-model="stopTime"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="stopTime"
      class="w-full" 
      />
      <span class="text-danger text-sm" v-show="errors.has('stopTime')">{{ errors.first("stopTime") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("numberOfBreak") }}<b style="color: #ff6141" >*</b></p>
      <vs-input-number
      disabled
      :min="0" 
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="numberOfBreak"
      v-model="numberOfBreak"
      class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('numberOfBreak')">{{ errors.first("numberOfBreak") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("distance") }}{{' '}}(Km)<b style="color: #ff6141" >*</b></p>
      <vs-input-number
      disabled
      :min="0" 
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="distance"
      v-model="distance"
      class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('distance')">{{ errors.first("distance") }}</span>
      </div>

      <div hidden class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>Avez-vous de(s) pause(s) à faire ?<b style="color: #ff6141" >*</b></p>
      <vs-checkbox 
      disabled
      v-validate="'required'"
      v-model="pause"
      name="pause"
      class="w-full">
      </vs-checkbox>
      <span class="text-danger text-sm" v-show="errors.has('pause')">{{ errors.first("pause") }}</span>
      </div>

      <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
      <label class="text-sm opacity-75">{{ $t("interventionPlan") }}<b style="color: #ff6141" >*</b></label>
      <vs-textarea
      disabled
      name="interventionPlan"
      v-validate="'required'"
      v-model="interventionPlan"
      rows="3"
      class="w-full" />
      <span class="text-danger text-sm" v-show="errors.has('interventionPlan')">{{ errors.first("interventionPlan") }}</span>
      </div>

      <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
      <label class="text-sm opacity-75">{{ $t("interventionPlanContact") }}<b style="color: #ff6141" >*</b></label>
      <vue-tel-input
      disabled
      v-model="interventionPlanContact"
      placeholder="Telephone"
      :mode="national"
      :autoFormat="false"
      :enabledCountryCode="true"
      :enabledFlags="true"
      :validCharactersOnly="true"
      data-vv-validate-on="blur"
      v-validate="'required|min:8|max:8'"
      @validate="yourValidationMethod"
      name="interventionPlanContact"
      class="w-full"
      >
      </vue-tel-input>
      <span class="text-danger text-sm" v-show="errors.has('interventionPlanContact')">{{ errors.first("interventionPlanContact") }}</span>
     
      <div class="vx-col p-6 sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
      <span><b>Fichier kmz</b></span>
      <br>
      <span><b style="color:orange">{{ name_file1_3 }}</b></span>
      </div>
      
    </div>

      <vx-card v-if="pause === 1 " class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
      <div class="flex items-end px-3">
      <feather-icon svgClasses="w-6 h-6" icon="PauseIcon" class="mr-2" />
      <span class="font-medium text-lg leading-none">Détails de la pause </span>
      </div>
      <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
      <h3>
      Pause(s)
      </h3>
      </template>
      <template slot="thead">
      <vs-th>N°</vs-th>
      <vs-th>{{ $t("sites") }}</vs-th>
      <vs-th>{{ $t("longitudes") }}</vs-th>
      <vs-th>{{ $t("latitudes") }}</vs-th>
      <vs-th>{{ $t("breakStartTimes") }}</vs-th>
      <vs-th>{{ $t("breakEndTimes") }}</vs-th>
      <vs-th>{{ $t("details") }}</vs-th>
      <vs-th>{{ $t("restriction") }}</vs-th>
      <vs-th>{{ $t("applicator") }}</vs-th>
      <vs-th>{{ $t("controller") }}</vs-th>
      <vs-th>{{ $t("measure") }}</vs-th>
      <vs-th>{{ $t("readAndApproved") }}</vs-th>
      </template>

      <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

      <vs-td  :data="data[indextr].id">
      {{data[indextr].id}}
      </vs-td>
      <vs-td :data="data[indextr].sites">
      {{data[indextr].sites}}
      </vs-td>
      <vs-td :data="data[indextr].longitudes">
      {{data[indextr].longitudes}}
      </vs-td>
      <vs-td :data="data[indextr].latitudes">
      {{data[indextr].latitudes}}
      </vs-td>
      <vs-td :data="data[indextr].breakStartTimes">
      {{data[indextr].breakStartTimes}}
      </vs-td>
      <vs-td :data="data[indextr].breakEndTimes">
      {{data[indextr].breakEndTimes}}
      </vs-td>
      <vs-td :data="data[indextr].details">
      {{data[indextr].details}}
      </vs-td>

      <vs-td :data="data[indextr].restrictions">
      {{data[indextr].restrictions}}
      </vs-td>

      <vs-td :data="data[indextr].applicators">
      {{data[indextr].applicators.fullName}}
      </vs-td>
      <vs-td :data="data[indextr].controllers">
      {{data[indextr].controllers.fullName}}
      </vs-td>
      <vs-td :data="data[indextr].measures">
      {{data[indextr].measures.measure}}
      </vs-td>
      <vs-td :data="data[indextr].readAndApproved">
      {{ApprouveObject[data[indextr].readAndApproved]}}
      </vs-td>

      </vs-tr>
      </template>
      </vs-table>
      </div>

      </vx-card>
      </div>
      <vs-button color="primary"  class="float-right mt-4" @click="quit()" >Quitter</vs-button>
    </div>

    <div v-if="activePrompt4"  class="clearfix">

    <div class="vx-row">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
      <p>{{ $t("purpose") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        disabled
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="purpose"
        v-model="purpose"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('purpose')">{{ errors.first("purpose") }}</span>
      </div>

      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
        <p>{{ $t("mission") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
        disabled
        name="mission_name"
        v-model="mission_name"
        class="w-full"/>

      </div>
      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("departurePlace") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          v-validate="'required'"
          v-model="departurePlace"
          data-vv-validate-on="blur"
          name="departurePlace"
          class="w-full"
          />
      <span class="text-danger text-sm" v-show="errors.has('departurePlace')">{{ errors.first("departurePlace") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("departurePlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          @blur="validateCoordinates_longitude1"
          :min="0" 
          type="number"
          v-validate="'required'"
          v-model="departurePlaceLongitude"
          data-vv-validate-on="blur"
          name="departurePlaceLongitude"
          class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('departurePlaceLongitude')">{{ errors.first("departurePlaceLongitude") }}</span>
      <br v-if="!isValid_longitude1">
      <span class="text-danger text-sm" v-if="!isValid_longitude1" >Longitude invalide.</span>

    </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("departurePlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          @blur="validateCoordinates_latitude1"
          :min="0" 
          type="number"
          v-validate="'required'"
          v-model="departurePlaceLatitude"
          data-vv-validate-on="blur"
          name="departurePlaceLatitude"
          class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('departurePlaceLatitude')">{{ errors.first("departurePlaceLatitude") }}</span>
      <br v-if="!isValid_latitude1">
      <span class="text-danger text-sm" v-if="!isValid_latitude1" >Latitude invalide.</span>
    </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlace") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          v-validate="'required'"
          v-model="arrivalPlace"
          data-vv-validate-on="blur"
          autocomplete
          name="arrivalPlace"
          class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('arrivalPlace')">{{ errors.first("arrivalPlace") }}</span>

      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlaceLongitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
        @blur="validateCoordinates_longitude2"
          :min="0" 
          type="number"
          v-validate="'required'"
          v-model="arrivalPlaceLongitude"
          data-vv-validate-on="blur"
          name="arrivalPlaceLongitude"
          class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLongitude')">{{ errors.first("arrivalPlaceLongitude") }}</span>
      <br v-if="!isValid_longitude2">
      <span class="text-danger text-sm" v-if="!isValid_longitude2" >Longitude invalide.</span>
    </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
      <p>{{ $t("arrivalPlaceLatitude") }}<b style="color: #ff6141" >*</b></p>
      <vs-input
          @blur="validateCoordinates_latitude2"
          :min="0" 
          type="number"
          v-validate="'required'"
          v-model="arrivalPlaceLatitude"
          data-vv-validate-on="blur"
          name="arrivalPlaceLatitude"
          class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('arrivalPlaceLatitude')">{{ errors.first("arrivalPlaceLatitude") }}</span>
      <br v-if="!isValid_latitude2">
      <span class="text-danger text-sm" v-if="!isValid_latitude2" >Latitude invalide.</span>
    </div>

    <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("departureTime") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      :config="configdateTimePicker"
      v-model="departureTime"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="departureTime"
      class="w-full" 
        />
      <span class="text-danger text-sm" v-show="errors.has('departureTime')">{{ errors.first("departureTime") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("duree") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      :config="configdateTimePicker1"
      v-model="duree"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="duree"
      class="w-full" 
      />
      <span class="text-danger text-sm" v-show="errors.has('duree')">{{ errors.first("duree") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("stopTime") }}<b style="color: #ff6141" >*</b></p>
      <flat-pickr 
      disabled
      :config="configdateTimePicker"
      v-model="stopTime"
      v-validate="'required'"
      data-vv-validate-on="blur"
      autocomplete
      name="stopTime"
      class="w-full" 
      />
      <span class="text-danger text-sm" v-show="errors.has('stopTime')">{{ errors.first("stopTime") }}</span>
      </div>
      
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("numberOfBreak") }}<b style="color: #ff6141" >*</b></p>
      <vs-input-number
      disabled
      :min="0" 
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="numberOfBreak"
      v-model="numberOfBreak"
      class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('numberOfBreak')">{{ errors.first("numberOfBreak") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>{{ $t("distance") }}{{' '}}(Km)<b style="color: #ff6141" >*</b></p>
      <vs-input-number
      :min="0" 
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="distance"
      v-model="distance"
      class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('distance')">{{ errors.first("distance") }}</span>
      </div>
      
      <div hidden class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <p>Avez-vous de(s) pause(s) à faire ?<b style="color: #ff6141" >*</b></p>
      <vs-checkbox 
      v-validate="'required'"
      v-model="pause"
      name="pause"
      class="w-full">
      </vs-checkbox>
      <span class="text-danger text-sm" v-show="errors.has('pause')">{{ errors.first("pause") }}</span>
      </div>

      <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
      <label class="text-sm opacity-75">{{ $t("interventionPlan") }}<b style="color: #ff6141" >*</b></label>
      <vs-textarea
      name="interventionPlan"
      v-validate="'required'"
      v-model="interventionPlan"
      rows="3"
      class="w-full" />
      <span class="text-danger text-sm" v-show="errors.has('interventionPlan')">{{ errors.first("interventionPlan") }}</span>
      </div>

      <div class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
      <label class="text-sm opacity-75">{{ $t("interventionPlanContact") }}<b style="color: #ff6141" >*</b></label>
      <vue-tel-input
        v-model="interventionPlanContact"
        placeholder="Telephone"
        :mode="national"
        :autoFormat="false"
        :enabledCountryCode="true"
        :enabledFlags="true"
        :validCharactersOnly="true"
        data-vv-validate-on="blur"
        v-validate="'required|min:8|max:8'"
        @validate="yourValidationMethod"
        name="interventionPlanContact"
        class="w-full"
      >
      </vue-tel-input>
      <span class="text-danger text-sm" v-show="errors.has('interventionPlanContact')">{{ errors.first("interventionPlanContact") }}</span>
      </div>

    <vx-card v-if="pause === 1 " class="mt-10" no-shadow card-border>
    <div class="vx-row ">
    <div class="vx-col w-full">
      <div class="flex items-end px-3">
        <feather-icon svgClasses="w-6 h-6" icon="PauseIcon" class="mr-2" />
        <span class="font-medium text-lg leading-none">Définissez la pause </span>
      </div>
      <br/>
      <b v-if="Check_update" style="color: #ff6141" >{{'Il vous reste'}}{{'  '}}{{this.New_hours_required}}{{ '  ' }}{{ 'pause(s) obligatoires à planifier'}}</b>
      <b v-if="!Check_update" style="color: #ff6141" >{{'Il vous reste'}}{{'  '}}{{this.New_hours_required_update}}{{ '  ' }}{{ 'pause(s) obligatoires à planifier'}}</b>

      <vs-divider />
    </div>
    </div>
    <div class="vx-row ">
    </div>
    <div class="vx-row ">
    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("site") }}<b style="color: #ff6141" >*</b></label>
    <vs-input
            v-model="sites_tab"
            autocomplete
            name="sites"
            class="w-full"
    />
    </div>

    <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("longitude") }}<b style="color: #ff6141" >*</b></label>
        <vs-input
            @blur="validateCoordinates_longitude3"
            :min="0" 
            type="number"
            v-model="longitudes_tab"
            name="longitudes"
            class="w-full"
        />
        <br v-if="!isValid_longitude3">
        <span class="text-danger text-sm" v-if="!isValid_longitude3" >Longitude invalide.</span>
    </div>

    <div class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
      <label class="text-sm opacity-75">{{ $t("latitude") }}<b style="color: #ff6141" >*</b></label>
        <vs-input
            @blur="validateCoordinates_latitude3"
            :min="0" 
            type="number"
            v-model="latitudes_tab"
            name="latitudes"
            class="w-full"
        />
        <br v-if="!isValid_latitude3">
        <span class="text-danger text-sm" v-if="!isValid_latitude3" >Latitude invalide.</span>
    </div>

    <div  class="vx-col  sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("breakStartTimes") }}<b style="color: #ff6141" >*</b></label>
    <v-select
        name="breakStartTimes"
        :reduce="rep => rep.hours"
        class="w-full" 
        label="name"
        v-model="breakStartTimes_tab"
        :options="New_hours">
      </v-select>
    <span class="text-danger text-sm pt-2" v-if="heure_check">L'heure définie n'est pas autorisée</span>

    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("details") }}<b style="color: #ff6141" >*</b></label>
    <vs-textarea
    name="details"
    v-model="details_tab"
    rows="3"
    class="w-full" />

    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("restriction") }}<b style="color: #ff6141" >*</b></label>
    <vs-textarea
    v-model="restrictions_tab"
    name="restrictions"
    rows="3"
    class="w-full" />
    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("applicator") }}<b style="color: #ff6141" >*</b></label>
    <vs-select 
    :closeOnSelect="false"
    v-model="applicators_tab"
    autocomplete
    name="applicators"
    class="w-full">
    <vs-select-item :key="item1" :value="item1" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("controller") }}<b style="color: #ff6141" >*</b></label>
    <vs-select 
    :closeOnSelect="false"
    v-model="controllers_tab"
    autocomplete
    name="controllers"
    class="w-full">
    <vs-select-item :key="item2" :value="item2" :text="item2.fullName" v-for="item2 in staffs" /></vs-select>
    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("measure") }}<b style="color: #ff6141" >*</b></label>
    <vs-select 
    :closeOnSelect="false"
    v-model="measures_tab"
    autocomplete
    name="measures"
    class="w-full">
    <vs-select-item :key="item3" :value="item3" :text="item3.measure" v-for="item3 in recommendedSefetyMeasures" /></vs-select>
    </div>

    <div  class="vx-col sm:w-1/5 md:w-1/5 lg:w-1/5 xs:w-1/5">
    <label class="text-sm opacity-75">{{ $t("readAndApproved") }}<b style="color: #ff6141" >*</b></label>

    <vs-checkbox 
          v-model="readAndApproved_tab"
          name="readAndApproved"
          class="w-full">
        </vs-checkbox>
    </div>

    <div class="vx-col md:w-full sm:w-full w-full ">
      <div class="flex flex-wrap justify-between mt-5 ">
          <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>

          <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
          
          <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

        </div>
    </div>
    </div>
    <div class="vx-row mt-4 ">
    <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
    <template slot="header">
    <h3>
    Pause(s)
    </h3>
    </template>
    <template slot="thead">
    <vs-th >N°</vs-th>
    <vs-th>{{ $t("sites") }}</vs-th>
    <vs-th>{{ $t("longitudes") }}</vs-th>
    <vs-th>{{ $t("latitudes") }}</vs-th>
    <vs-th>{{ $t("breakStartTimes") }}</vs-th>
    <vs-th>{{ $t("details") }}</vs-th>
    <vs-th>{{ $t("restriction") }}</vs-th>
    <vs-th>{{ $t("applicator") }}</vs-th>
    <vs-th>{{ $t("controller") }}</vs-th>
    <vs-th>{{ $t("measure") }}</vs-th>
    <vs-th>{{ $t("readAndApproved") }}</vs-th>

    <vs-th>Actions</vs-th>
    </template>

    <template slot-scope="{data}">
    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

      <vs-td  :data="data[indextr].id">
        {{data[indextr].id}}
      </vs-td>
      <vs-td :data="data[indextr].sites">
        {{data[indextr].sites}}
      </vs-td>
      <vs-td :data="data[indextr].longitudes">
        {{data[indextr].longitudes}}
      </vs-td>
      <vs-td :data="data[indextr].latitudes">
        {{data[indextr].latitudes}}
      </vs-td>
      <vs-td :data="data[indextr].breakStartTimes">
        {{data[indextr].breakStartTimes}}
      </vs-td>
      
      <vs-td :data="data[indextr].details">
        {{data[indextr].details}}
      </vs-td>

      <vs-td :data="data[indextr].restrictions">
        {{data[indextr].restrictions}}
      </vs-td>

      <vs-td :data="data[indextr].applicators">
        {{data[indextr].applicators.fullName}}
      </vs-td>
      <vs-td :data="data[indextr].controllers">
        {{data[indextr].controllers.fullName}}
      </vs-td>
      <vs-td :data="data[indextr].measures">
        {{data[indextr].measures.measure}}
      </vs-td>
      <vs-td :data="data[indextr].readAndApproved">
        {{ApprouveObject[data[indextr].readAndApproved]}}
      </vs-td>
      <vs-td class="whitespace-no-wrap">
        <div class="flex">
          <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
          
          <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
      
        </div>
      </vs-td>
    </vs-tr>
    </template>
    </vs-table>
    </div>

    </vx-card>
    </div>
    <vs-button color="success"  class="float-right mt-4" @click="submitData_trajet()" >Valider-Continuer</vs-button>
    </div>

    </div>
  </template>
<script>
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
const isValidCoordinates = require('is-valid-coordinates')

const dict = {
  custom: {
    interventionPlanContact: {
      required: 'Le champ Plan d\'intervention contact est obligatoire'
    },
    interventionPlan: {
      required: 'Le champ Plan d\'intervention est obligatoire'
    },
    departurePlace: {
      required: 'Le champ lieu de départ est obligatoire'
    },
    departurePlaceLongitude: {
      required: 'Le champ Longitude du lieu de départ  est obligatoire'
    },
    departurePlaceLatitude: {
      required: 'Le champ Latitude du lieu de départ  est obligatoire'
    },
    arrivalPlace: {
      required: 'Le champ lieu d\'arrivé est obligatoire'
    },
    arrivalPlaceLongitude:{
      required:'Le champ Latitude du lieu d\'arrivé est obligatoire'
    },
    arrivalPlaceLatitude:{
      required:'Le champ Longitude du lieu d\'arrivé est obligatoire'
    },
    mission: {
      required: 'Le champ nom mission  est obligatoire'
    },
    departureTime: {
      required: 'Le champ l\'heure de depart est obligatoire'
    },
    stopTime: {
      required: 'Le champ l\'heure d\'arriver est obligatoire'
    },
    distance: {
      required: 'Le champ distance est obligatoire'
    },
    duree: {
      required: 'Le champ durée est obligatoire'
    },
    numberOfBreak: {
      required: 'Le champ nombre de pause est obligatoire'
    },
    pause: {
      required: 'Le champ pause est obligatoire'
    },
    purpose: {
      required: 'Le champ but est obligatoire'
    },
   
    sites: {
      required: 'Le champ site est obligatoire'
    },
    breakStartTimes: {
      required: 'Le champ début de pause est obligatoire'
    },
    breakEndTimes: {
      required: 'Le champ fin de pause est obligatoire'
    },
    details: {
      required: 'Le champ détail est obligatoire'
    }
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      action_button:'',
      missionControle_data:'',
      mission_name:'',
      mission_id: '',
      mission_data:{},
      isValid_longitude1:true,
      isValid_latitude1: true,
      isValid_longitude2:true,
      isValid_latitude2: true,
      isValid_longitude3:true,
      isValid_latitude3:true,
      id : '',
      id_produit:1,
      produits_computed:[],
      produit_submit:[],
      old_produitID:[],
      repProduit:[],
      itemIndexProduit:-1,
      update_produitChek : false,
      Has_Med_Dos_Qty_update : false,
      heure_check : false,

      sites_tab: null,
      breakStartTimes_tab: '',
      breakEndTimes_tab: '',
      details_tab:'',
      longitudes_tab: 0,
      latitudes_tab: 0,
      restrictions_tab: '',
      applicators_tab:null,
      controllers_tab:null,
      measures_tab: null,

      applicators_tab_old:null,
      controllers_tab_old:null,
      measures_tab_old: null,

      readAndApproved_tab: 0,
      

      departurePlaceLongitude:0,
      departurePlaceLatitude: 0,

      arrivalPlaceLongitude:0,
      arrivalPlaceLatitude: 0,

      interventionPlan : '',
      interventionPlanContact: '',

      longitudes: [],
      latitudes: [],
      restrictions: [],
      applicators: [],
      controllers:[],
      measures: [],
      readAndApproved: [],

      mission: null,     
      departurePlace: null,   
      arrivalPlace: null,  

      departureTime : '',
      stopTime: '',

      distance: 0,     
      duree : '',
      numberOfBreak : 0,
      pause : 0,
      purpose : '',
      sites: [],
      breakStartTimes: [],
      breakEndTimes: [],
      details: [],

      configdateTimePicker: {
        dateFormat: 'H:i',
        enableTime: true,
        enableSeconds: true,
        locale: French,
        noCalendar: true
      },
      configdateTimePicker1: {
        dateFormat: 'H:i:s',
        enableTime: true,
        enableSeconds: true,
        locale: French,
        noCalendar: true
      },
      activePrompt4:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,      
      missions: [],
      journeys: [],
     
      selected: [],
      isMounted: false,
      itemsPerPage: 20,
     
      heure_duree : 0,
      minute_duree : 0,
      heure_departureTime: 0,
      minute_departureTime: 0,
      New_hours : [],
      New_hours_required :0,
      New_hours_required_update :0,
      staffs:[],
      recommendedSefetyMeasures:[],

      ApprouveObject: {
        true: 'Oui',
        false: 'Non'
      },
      show_update : false,
      objet_verification :false,
      dataImg1_3:'',
      name_file1_3:'',
      file_view:'',
      fileLenght:'',
      break_object:'',
      restriction_object:'',
      measure_object:''

    }
  },
  components: {
    flatPickr
  },
  watch: {
    numberOfBreak (value) {
      if (value) {
        const hoursBreaks = this.changeHoursObject(this.departureTime)
        let heure_duree = hoursBreaks.heure
        const minute_duree = hoursBreaks.minute
        this.New_hours = []
        if (this.show_update === false) {
          this.produits_computed = []
        }
       
        for (let i = 0; i < value; i++) {
          heure_duree = heure_duree + 2
          if (heure_duree <= 18) {
            const hours = {
              name : `${heure_duree  }:${minute_duree}`,
              hours :`${heure_duree }:${minute_duree}`
            }
            this.New_hours.push(hours)
            //this.New_hours_required = this.New_hours.length
          }
         
        }

      }
      if (value) {
        this.pause = 1
      } else {
        this.pause = 0
        this.produits_computed = []
      }
    },

    duree (value) {
      if (value) {
        const hoursBreaks = this.changeHoursObject(value)
        this.heure_duree = hoursBreaks.heure
        this.minute_duree = hoursBreaks.minute
        this.numberOfBreak = this.test_nombreDeDivisionsParDeux(parseInt(hoursBreaks.heure))
        this.New_hours_required = this.numberOfBreak
       
        if (this.New_hours_required !== this.numberOfBreak_old) {
          this.produits_computed = []
          this.New_hours_required = this.numberOfBreak
          this.New_hours_required_update = this.numberOfBreak
        } else {
          this.New_hours_required_update = 0
        }
        this.stopTime = `${this.heure_departureTime + this.heure_duree}:${this.minute_departureTime + this.minute_duree}`
        if (this.numberOfBreak) {
          this.pause = 1
          if (this.show_update === false) {
            this.produits_computed = []
          }
        } else {
          this.pause = 0
          if (this.show_update === false) {
            this.produits_computed = []
          }
        }
      }
    },

    departureTime (value) {
      if (value) {
        const hoursBreaks = this.changeHoursObject(value)
        this.heure_departureTime = hoursBreaks.heure
        this.minute_departureTime = hoursBreaks.minute
        this.stopTime = `${this.heure_departureTime + this.heure_duree}:${this.minute_departureTime + this.minute_duree}`
        let heure_duree = this.heure_departureTime 
        if (this.numberOfBreak) {
          this.pause = 1
          if (this.show_update === false) {
            this.produits_computed = []
          }
          this.breakStartTimes_tab = null
        } else {
          this.pause = 0
          if (this.show_update === false) {
            this.produits_computed = []
          }
          this.breakStartTimes_tab = null

        }
        if (this.numberOfBreak) {
          this.New_hours = []
          for (let i = 0; i < this.numberOfBreak; i++) {
            heure_duree = heure_duree + 2
            if (heure_duree <= 18) {
              const hours = {
                name : `${heure_duree  }:${this.minute_departureTime}`,
                hours :`${heure_duree }:${this.minute_departureTime}`
              }
              this.New_hours.push(hours)
              //this.New_hours_required = this.New_hours.length

            }
         
          }
        }
      }
    },
    breakStartTimes_tab (value) {
      if (value) {
        const hoursBreaks = this.changeHoursObject(value)
        const heure = hoursBreaks.heure
        const minute = hoursBreaks.minute
             
        if (heure >=  4 && heure <= 18) {
          
          if (heure === 18 && minute <= 30) {
            this.heure_check = false
          } else if (heure === 4 && minute > 0) {
            this.heure_check = true
          } else {
            this.heure_check = false
          }
          
        } else {
          this.heure_check = true
        }    

      }
    }
  },
  computed: {
    validate () {
      if (
        (
          this.sites_tab !== null  
          && this.breakStartTimes_tab !== ''
          && this.details_tab !== '' 
          && this.heure_check === false
          && this.isValid_longitude3 === true
          && this.isValid_latitude3 === true
          && this.longitudes_tab !== ''
          && this.latitudes_tab !== ''
          && this.restrictions_tab !== ''
          && this.applicators_tab !== null
          && this.measures_tab !== null
          && this.controllers_tab
        )) return true
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.journeys.length
    }
  },
  methods: {
    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        this.dataImg1_3 = input.target.files[0]
        this.name_file1_3 = input.target.files[0].name
      }
    },
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    validateCoordinates_longitude1 () {
      this.isValid_longitude1 = isValidCoordinates.longitude(parseFloat(this.departurePlaceLongitude))
    },
    validateCoordinates_latitude1 () {
      this.isValid_latitude1 = isValidCoordinates.latitude(parseFloat(this.departurePlaceLatitude))
    },
    validateCoordinates_longitude2 () {
      this.isValid_longitude2 = isValidCoordinates.longitude(parseFloat(this.arrivalPlaceLongitude))
    },
    validateCoordinates_latitude2 () {
      this.isValid_latitude2 = isValidCoordinates.latitude(parseFloat(this.arrivalPlaceLatitude))
    },
    validateCoordinates_longitude3 () {
      this.isValid_longitude3 = isValidCoordinates.longitude(parseFloat(this.longitudes_tab))
    },
    validateCoordinates_latitude3 () {
      this.isValid_latitude3 = isValidCoordinates.latitude(parseFloat(this.latitudes_tab))
    },
    test_nombreDeDivisionsParDeux (nombre) {
      let compteur = 0
      while (nombre >= 2 && nombre !== 0) {
        nombre = nombre - 2  
        compteur++ 
      }
      return compteur
    },
    changeHours (value) {
      if (value) {
        // Heure au format HH:mm:ss
        const heureComplete = value

        // Découper l'heure en heures, minutes et secondes
        const parties = heureComplete.split(':')

        // Extraire les valeurs
        const heures = parseInt(parties[0], 10)
        const minutes = parseInt(parties[1], 10)
        //const secondes = parseInt(parties[2], 10)
      
        return `${heures}:${minutes}`
      }
    },
    changeHoursObject (value) {
      if (value) {
        // Heure au format HH:mm:ss
        const heureComplete = value

        // Découper l'heure en heures, minutes et secondes
        const parties = heureComplete.split(':')

        // Extraire les valeurs
        const heures = parseInt(parties[0], 10)
        const minutes = parseInt(parties[1], 10)
        //const secondes = parseInt(parties[2], 10)
        const hours = {
          heure : heures,
          minute :minutes
        }
        return hours
      }
    },
    submit_produit () {
      this.produit_submit.length = 0
      const obj = {
        id: this.id_produit++,
        sites: this.sites_tab,
        breakStartTimes: this.breakStartTimes_tab,
        breakEndTimes: this.breakEndTimes_tab,
        details:this.details_tab,
        longitudes:this.longitudes_tab,
        latitudes:this.latitudes_tab,
        restrictions:this.restrictions_tab,
        applicators:this.applicators_tab,
        measures : this.measures_tab,
        readAndApproved : this.readAndApproved_tab,
        controllers:this.controllers_tab
      }
      this.produit_submit = obj
      //this.FIND_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
      //if (this.itemIndexProduit === -1) {
      
      if (this.Check_update === false) {
        this.New_hours_required = this.New_hours_required - 1
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
        this.$vs.notify({
          color:'success',
          icon:'done',
          text:'Pause ajoutée à la liste',
          position:'top-right'
        })
      } else {
        this.New_hours_required_update = this.New_hours_required_update - 1
        if (this.New_hours_required_update !== -1) {
          this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
          this.$vs.notify({
            color:'success',
            icon:'done',
            text:'Pause ajoutée à la liste',
            position:'top-right'
          })
        } else {
          this.New_hours_required_update = 0
          this.$vs.notify({
            color:'warning',
            iconPack: 'feather',
            icon:'icon-alert-circle',
            time:15000,
            text:'Nombre de pauses suffisantes',
            position:'top-center'
          })
        }
      }
      this.validateFormation_intialise()
      // } //else if (this.itemIndexProduit !== -1) {
      //this.$vs.notify({
      /*    color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'La pause existe déjà dans la liste',
          position:'top-center'
        })
      } */
     
    },
    validateFormation_intialise () {
      this.sites_tab = ''
      this.breakStartTimes_tab = ''
      this.breakEndTimes_tab = ''
      this.details_tab = ''
      this.longitudes_tab = 0
      this.latitudes_tab = 0
      this.restrictions_tab = ''
      this.applicators_tab = null
      this.controllers_tab = null
      this.measures_tab = null

      this.applicators_tab_old = null
      this.controllers_tab_old = null
      this.measures_tab_old = null

      this.isValid_longitude3 = true
      this.isValid_latitude3 = true

      this.readAndApproved_tab = false
      this.heure_check = false
      this.update_produitChek = false
    },
    resetData () {
      this.id = ''
      this.sites_tab = ''
      this.breakStartTimes_tab = ''
      this.breakEndTimes_tab = ''
      this.details_tab = ''
      this.longitudes_tab = ''
      this.latitudes_tab = ''
      this.restrictions_tab = ''

      this.applicators_tab = null
      this.controllers_tab = null
      this.measures_tab = null

      this.applicators_tab_old = null
      this.controllers_tab_old = null
      this.measures_tab_old = null
      
      this.readAndApproved_tab = false
      this.update_produitChek = false
      this.heure_check = false


    }, 
    deleteProduit (id) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Pause supprimée de la liste',
        position:'top-right'
      })
      if (this.Check_update === false) {
        this.New_hours_required = this.New_hours_required + 1
      } else {
        this.New_hours_required_update = this.New_hours_required_update + 1
      }
    },
    update_pro_chek (prod) {
      this.id = prod.id
      this.sites_tab = prod.sites
      this.breakStartTimes_tab = prod.breakStartTimes
      this.breakEndTimes_tab = prod.breakEndTimes
      this.details_tab = prod.details
      this.longitudes_tab = prod.longitudes
      this.latitudes_tab = prod.latitudes
      this.restrictions_tab = prod.restrictions
      if (this.show_update === true) {
        this.applicators_tab = prod.applicators.fullName
        this.controllers_tab = prod.controllers.fullName
        this.measures_tab = prod.measures.measure

        this.applicators_tab_old = prod.applicators
        this.controllers_tab_old = prod.controllers
        this.measures_tab_old = prod.measures

      } else {
        this.applicators_tab = prod.applicators
        this.controllers_tab = prod.controllers
        this.measures_tab = prod.measures
      }
     
      this.readAndApproved_tab = prod.readAndApproved
      this.update_produitChek = true
    },
  
    updateProduit () {
      this.load()
      let objet_measures = ''
      let objet_applicators = ''
      let objet_controllers = ''

      if (this.measures_tab instanceof Object) {
        objet_measures = this.measures_tab
      } else {
        objet_measures = this.measures_tab_old
      }

      if (this.applicators_tab instanceof Object) {
        objet_applicators = this.applicators_tab

      } else {
        objet_applicators = this.applicators_tab_old
      }

      if (this.controllers_tab instanceof Object) {
        objet_controllers = this.controllers_tab

      } else {
        objet_controllers = this.controllers_tab_old
      }
      const obj = {
        id: this.id,
        sites: this.sites_tab,
        breakStartTimes: this.breakStartTimes_tab,
        breakEndTimes: this.breakEndTimes_tab,
        details:this.details_tab,
        longitudes:this.longitudes_tab,
        latitudes: this.latitudes_tab,
        restrictions: this.restrictions_tab,
        applicators: objet_applicators,
        measures : objet_measures,
        readAndApproved : this.readAndApproved_tab,
        controllers: objet_controllers
        
      }
      this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
      this.acceptAlert()
      this.resetData()
    },
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:'Pause modifiée',
        position:'top-right'
      })
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.push(produit)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.formations.id === produit.formations.id)
    },

    saveEditTeste () {
      const produits_store = this.produits_computed
      for (let i = 0; i < produits_store.length; i++) {
        this.New_produitID.push(produits_store[i].id)
      }
      for (let i = 0; i < this.New_produitID.length; i++) {
        const supprOp = this.New_produitID[i]
        this.removeItemAll(this.old_produitID, supprOp)
      }

    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.Check_update = false
      this.update_produitChek = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
   
    intialise () {
      //this.mission_data = {}
      this.isValid_longitude1 = true
      this.isValid_latitude1 = true
      this.isValid_longitude2 = true
      this.isValid_latitude2 = true
      this.isValid_longitude3 = true
      this.isValid_latitude3 = true

      this.break_object = []
      this.restriction_object = []
      this.measure_object = []


      this.id = ''
      this.update_HasId = ''
      this.mission = null  
      this.departurePlace = null  
      this.arrivalPlace = null
      this.departureTime = ''
      this.stopTime = ''
      this.distance = 0   
      this.duree = ''
      this.numberOfBreak = 0
      this.pause = 0
      this.purpose = ''
      this.sites = []
      this.breakStartTimes = []
      this.breakEndTimes = []
      this.details = []

      this.longitudes = []
      this.latitudes = []
      this.restrictions = []
      this.applicators = []
      this.measures = []
      this.readAndApproved = []
      this.controllers_tab = []


      this.departurePlaceLongitude = 0
      this.departurePlaceLatitude = 0

      this.arrivalPlaceLongitude = 0
      this.arrivalPlaceLatitude = 0

      this.interventionPlan = ''
      this.interventionPlanContact = ''

      this.produits_computed = []
      this.validateFormation_intialise()
      this.show_update = false
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false
      this.activePrompt4 = false

    },
      
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`journeys/${id}/`)
            .then(response => {
              this.getAllJourneys()
              window.getPrendTaCom.success('Le trajet est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression du trajet a échoué.' })
            })
        }
      })
  
    },
    intialisePause () {
      this.sites = []
      this.breakStartTimes = []
      this.details = []
      this.longitudes = []
      this.latitudes = []
      this.restrictions = []
      this.applicators = []
      this.measures = []
      this.readAndApproved = []
      this.controllers = []
    },
    ShowDataTrajet (ord) {
      this.intialise()
      this.id_produit =  ''
      this.update_HasId = ord.id
      this.produits_computed = []
      this.repProduit.length = 0

      this.mission = ord.mission.id
      this.purpose = `${ord.purpose}(${'Suite'})`
      //this.departurePlace = ord.departurePlace.id
      //this.arrivalPlace = ord.arrivalPlace.id
      
      this.activePrompt4 = true

    },
    ShowData (ord) {
      this.intialise()
      this.show_update = true
      this.id_produit =  ''
      this.update_HasId = ord.id
      this.produits_computed = []
      this.repProduit.length = 0

      this.mission = ord.mission.id  
      this.departurePlace = ord.departurePlace
      this.arrivalPlace = ord.arrivalPlace
      this.departureTime = ord.departureTime
      this.stopTime = ord.stopTime
      this.distance = ord.distance
      this.duree = ord.duree
      this.numberOfBreak = ord.numberOfBreak
      this.numberOfBreak_old = ord.numberOfBreak
      this.purpose = ord.purpose
      
      this.departurePlaceLongitude = ord.departurePlaceLongitude
      this.departurePlaceLatitude = ord.departurePlaceLatitude

      this.arrivalPlaceLongitude = ord.arrivalPlaceLongitude
      this.arrivalPlaceLatitude = ord.arrivalPlaceLatitude

      this.interventionPlan = ord.interventionPlan
      this.interventionPlanContact = ord.interventionPlanContact
      this.name_file1_3 = ord.kmzFile.substring(47)

      for (let i = 0; i < ord.breacks.length; i++) {
  
        const obj = {
          id: '',
          sites: '',
          breakStartTimes: '',
          breakEndTimes: '',
          details:'',
          longitudes:'',
          latitudes: '',
          restrictions: '',
          applicators:'',
          measures : '',
          readAndApproved : '',
          controllers: ''
        }
        obj.id = this.id_produit++
        obj.sites = ord.breacks[i].site
        obj.breakStartTimes = ord.breacks[i].breakStartTime
        obj.breakEndTimes = ord.breacks[i].breakEndTime
        obj.details = ord.breacks[i].details
        obj.longitudes = ord.breacks[i].longitude 
        obj.latitudes = ord.breacks[i].latitude

        obj.restrictions = ord.staffHasRestriction[i].restrictions
        obj.applicators = ord.staffHasRestriction[i].applicator
        obj.controllers = ord.staffHasRestriction[i].controller

        obj.measures = ord.journeyHasMeasures[i].measure
        obj.readAndApproved = ord.journeyHasMeasures[i].readAndApproved
       

        this.old_produitID.push(ord.breacks[i].id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      this.activePrompt3 = true

    },
    
    editData (ord) {
      this.intialise()
      this.show_update = true
      this.Check_update = true
      this.id_produit =  ''
      this.update_HasId = ord.id
      this.produits_computed = []
      this.repProduit.length = 0

      this.mission = ord.mission.id  
      this.departurePlace = ord.departurePlace
      this.arrivalPlace = ord.arrivalPlace
      this.departureTime = this.changeHours(ord.departureTime)
      this.stopTime = this.changeHours(ord.stopTime)
      this.distance = ord.distance
      this.duree = ord.duree
      this.numberOfBreak = ord.numberOfBreak
      this.numberOfBreak_old = ord.numberOfBreak
      this.purpose = ord.purpose
 
      this.departurePlaceLongitude = ord.departurePlaceLongitude
      this.departurePlaceLatitude = ord.departurePlaceLatitude

      this.arrivalPlaceLongitude = ord.arrivalPlaceLongitude
      this.arrivalPlaceLatitude = ord.arrivalPlaceLatitude

      this.interventionPlan = ord.interventionPlan
      this.interventionPlanContact = ord.interventionPlanContact
      this.name_file1_3 = ord.kmzFile.substring(47)

      for (let i = 0; i < ord.breacks.length; i++) {
  
        const obj = {
          id: '',
          sites: '',
          breakStartTimes: '',
          breakEndTimes: '',
          details:'',
          longitudes:'',
          latitudes: '',
          restrictions: '',
          applicators:'',
          measures : '',
          readAndApproved : '',
          controllers: ''
        }
        obj.id = this.id_produit++
        obj.sites = ord.breacks[i].site
        obj.breakStartTimes = this.changeHours(ord.breacks[i].breakStartTime)
        obj.breakEndTimes = this.changeHours(ord.breacks[i].breakEndTime)
        obj.details = ord.breacks[i].details
        obj.longitudes = ord.breacks[i].longitude 
        obj.latitudes = ord.breacks[i].latitude

        obj.restrictions = ord.staffHasRestriction[i].restrictions
        obj.applicators = ord.staffHasRestriction[i].applicator
        obj.controllers = ord.staffHasRestriction[i].controller

        obj.measures = ord.journeyHasMeasures[i].measure
        obj.readAndApproved = ord.journeyHasMeasures[i].readAndApproved
       

        this.old_produitID.push(ord.breacks[i].id)
        this.repProduit.push(obj)
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      //this.New_hours_required = this.produits_computed.length
      this.activePrompt2 = true

    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result & this.isValid_longitude1 & this.isValid_latitude1 & this.isValid_longitude2 & this.isValid_latitude2) {
          if (this.numberOfBreak === this.produits_computed.length) {
            this.$vs.loading()
            const formdata = new FormData()
            const produits_store = this.produits_computed
            for (let i = 0; i < produits_store.length; i++) {
              const obj1 = {
                site: '',
                breakStartTime: '',
                details: '',
                longitude: '',
                latitude: ''
              }
              const obj2 = {
                restrictions: '',
                applicator: '',
                controller: ''

              }
              const obj3 = { 
                measure: '',
                readAndApproved: ''
              }

              obj1.site = (produits_store[i].sites)
              obj1.breakStartTime = (produits_store[i].breakStartTimes)
              obj1.details = (produits_store[i].details)
              obj1.longitude = (parseFloat(produits_store[i].longitudes))
              obj1.latitude = (parseFloat(produits_store[i].latitudes))
            
              obj2.restrictions = (produits_store[i].restrictions)
              obj2.applicator = (produits_store[i].applicators.id)
              obj2.controller = (produits_store[i].controllers.id)

              obj3.measure = (produits_store[i].measures.id)
              obj3.readAndApproved = (produits_store[i].readAndApproved)

              this.break_object.push(obj1)
              this.restriction_object.push(obj2)
              this.measure_object.push(obj3)


            }
            this.break_object = JSON.stringify(this.break_object)
            this.restriction_object = JSON.stringify(this.restriction_object)
            this.measure_object = JSON.stringify(this.measure_object)

            if (this.Check_update === false) {
              this.input = {
                mission: this.mission_id,     
                departurePlace: this.departurePlace,   
                arrivalPlace: this.arrivalPlace,  
                departureTime : this.departureTime,
                stopTime : this.stopTime,
              
                distance: parseFloat(this.distance),     
                duree : this.duree,
                numberOfBreak : parseInt(this.numberOfBreak),
                pause :  parseInt(this.pause),
                purpose : this.purpose,
              
                departurePlaceLongitude:parseFloat(this.departurePlaceLongitude),
                departurePlaceLatitude: parseFloat(this.departurePlaceLatitude),
                arrivalPlaceLongitude:parseFloat(this.arrivalPlaceLongitude),
                arrivalPlaceLatitude: parseFloat(this.arrivalPlaceLatitude),
              
                interventionPlan : this.interventionPlan,
                interventionPlanContact: this.interventionPlanContact
              }
            } else if (this.Check_update === true) {

              this.input = {
                id: this.update_HasId,
                mission: this.mission_id,     
                departurePlace: this.departurePlace,   
                arrivalPlace: this.arrivalPlace,  
                departureTime : this.departureTime,
                stopTime : this.stopTime,
                distance: parseFloat(this.distance),     
                duree : this.duree,
                numberOfBreak : parseInt(this.numberOfBreak),
                pause :  parseInt(this.pause),
                purpose : this.purpose,

                departurePlaceLongitude:parseFloat(this.departurePlaceLongitude),
                departurePlaceLatitude: parseFloat(this.departurePlaceLatitude),
                arrivalPlaceLongitude:parseFloat(this.arrivalPlaceLongitude),
                arrivalPlaceLatitude: parseFloat(this.arrivalPlaceLatitude),
              
                interventionPlan : this.interventionPlan,
                interventionPlanContact: this.interventionPlanContact
              }
            }
            if (this.dataImg1_3) {
              formdata.append('kmzFile', this.dataImg1_3,  this.dataImg1_3.name)
            }
            if (this.break_object) {
              formdata.append('break_object', this.break_object)
            }
            if (this.restriction_object) {
              formdata.append('restriction_object', this.restriction_object)
            }
            if (this.measure_object) {
              formdata.append('measure_object', this.measure_object)
            }
            const keys = [
              'mission', 'departurePlace', 'arrivalPlace', 'departureTime', 'stopTime', 'distance', 
              'duree', 'numberOfBreak', 'pause', 'purpose',
              'departurePlaceLongitude', 'departurePlaceLatitude', 'arrivalPlaceLongitude',
              'arrivalPlaceLatitude', 'interventionPlan', 'interventionPlanContact'

            ]
            const length = keys.length
            for (let j = 0; j < length; j++) {
              const item = keys[j]
              formdata.append(item, this.input[item])
            }
            let url = 'journeys/'
            let methods = 'post'
            const message = {
              error: 'Votre enregistrement a échoué.',
              success: 'Le trajet  est enrégistré avec succès.'
            }
            if (this.update_HasId) {
              url += `${this.update_HasId}/`
              methods = 'put'
              message.success = 'Le trajet est modifié avec succès.'
  
            }
  
            this.$http[methods](url,  formdata)
              .then((response) => {
                window.journeys.getAllJourneys()
                window.getPrendTaCom.success(message.success, response)
                this.intialise()
                this.missionStep()
              })
              .catch((error) => {
                const clefs = Object.keys(error.response.data)
                for (let i = 0; i < clefs.length; i++) {
                  const item = clefs[i]
                  if (item === 'mission') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ mission est obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'departurePlace') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ lieu de départ est obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'arrivalPlace') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ lieu d\'arrivé obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'departureTime') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ l\'heure de départ obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'stopTime') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ l\'heure d\'arrêt obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'distance') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ distance obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'duree') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ la durée est obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'numberOfBreak') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Le champ nombre de pause est obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'pause') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ pause est obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'purpose') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Champ but es obligatoire',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'sites') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Le champs site est obligatiore',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'breakStartTimes') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Le champs début de pause est obligatiore',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'breakEndTimes') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Le champs fin de pause est obligatiore',
                      color: 'warning',
                      position: 'top-center'
                    })
                  } else if (item === 'details') {
                    this.$vs.notify({
                      time: 4000,
                      title: 'ATTENTION',
                      text: 'Le champs detail est obligatiore',
                      color: 'warning',
                      position: 'top-center'
                    })
                  }
                }
                this.$vs.notify({
                  time: 10000,
                  title: 'ENREGISTREMENT ÉCHOUÉ',
                  text: error.response.data.message,
                  color: 'danger',
                  position: 'top-right'
                })
                this.produits_computed = []
                this.intialisePause()
                //this.activePrompt2 = false
                //this.activePrompt1 = false
                this.$vs.loading.close()
              })
          
          
          } else if (this.numberOfBreak  <  this.produits_computed.length) {

            this.$vs.notify({
              time: 25000,
              title: 'ATTENTION',
              text: `Vous avez droit à ${this.numberOfBreak} pause(s) obligatoire(s) à planifier`,
              color: 'warning',
              position: 'top-center'
            })
          } else if (this.numberOfBreak > this.produits_computed.length) {

            this.$vs.notify({
              time: 25000,
              title: 'ATTENTION',
              text: `Vous avez droit à ${this.numberOfBreak} pause(s) obligatoire(s) à planifier`,
              color: 'warning',
              position: 'top-center'
            })
          } else {

            this.$vs.notify({
              time: 25000,
              title: 'ATTENTION',
              text: `Vous avez droit à ${this.numberOfBreak} pause(s) obligatoire(s) à planifier`,
              color: 'warning',
              position: 'top-center'
            })
          }
        } else {

          this.$vs.notify({
            time: 25000,
            title: 'ATTENTION-ERREUR',
            text: 'VALIDATION',
            color: 'warning',
            position: 'top-center'
          })
        }
      })
        
    },
    submitData_trajet () {
      this.$validator.validateAll().then((result) => {
        if (result & this.isValid_longitude1 & this.isValid_latitude1 & this.isValid_longitude2 & this.isValid_latitude2) {
          this.$vs.loading()
          const produits_store = this.produits_computed
          for (let i = 0; i < produits_store.length; i++) {
            this.sites.push(produits_store[i].sites)
            this.breakStartTimes.push(produits_store[i].breakStartTimes)
            this.breakEndTimes.push(produits_store[i].breakEndTimes)
            this.details.push(produits_store[i].details)
            this.longitudes.push(parseFloat(produits_store[i].longitudes))
            this.latitudes.push(parseFloat(produits_store[i].latitudes))
            this.restrictions.push(produits_store[i].restrictions)
            this.applicators.push(produits_store[i].applicators.id)
            this.measures.push(produits_store[i].measures.id)
            this.readAndApproved.push(produits_store[i].readAndApproved)
            this.controllers.push(produits_store[i].controllers.id)
          }
          
          this.input = {
            principalJourney:this.update_HasId,
            mission: this.mission,     
            departurePlace: this.departurePlace,   
            arrivalPlace: this.arrivalPlace,  
            departureTime : this.departureTime,
            stopTime : this.stopTime,
              
            distance: parseFloat(this.distance),     
            duree : this.duree,
            numberOfBreak : parseInt(this.numberOfBreak),
            pause : this.pause,
            purpose : this.purpose,
              
            sites: this.sites,
            breakStartTimes: this.breakStartTimes,
            breakEndTimes: this.breakEndTimes,
            details: this.details,
            longitudes:this.longitudes,
              
            latitudes:this.latitudes,
            restrictions:this.restrictions,
            applicators:this.applicators,
            measures:this.measures,
            readAndApproved:this.readAndApproved,
              
            controllers:this.controllers,
            departurePlaceLongitude:parseFloat(this.departurePlaceLongitude),
            departurePlaceLatitude: parseFloat(this.departurePlaceLatitude),
            arrivalPlaceLongitude:parseFloat(this.arrivalPlaceLongitude),
            arrivalPlaceLatitude: parseFloat(this.arrivalPlaceLatitude),
              
            interventionPlan : this.interventionPlan,
            interventionPlanContact: this.interventionPlanContact
          }

          const url = 'journeys/'
          const methods = 'post'
          const message = {
            error: 'Votre enregistrement a échoué.',
            success: 'Contunier votre trajet avec succès.'
          }
          
          this.$http[methods](url, this.input)
            .then((response) => {
              window.journeys.getAllJourneys()
              window.getPrendTaCom.success(message.success, response)
              this.intialise()
              this.missionStep()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'mission') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ mission est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'departurePlace') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ lieu de départ est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'arrivalPlace') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ lieu d\'arrivé obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'departureTime') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ l\'heure de départ obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'stopTime') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ l\'heure d\'arrêt obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'distance') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ distance obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'duree') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ la durée est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'numberOfBreak') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Le champ nombre de pause est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'pause') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ pause est obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'purpose') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ but es obligatoire',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'sites') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Le champs site est obligatiore',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'breakStartTimes') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Le champs début de pause est obligatiore',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'breakEndTimes') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Le champs fin de pause est obligatiore',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'details') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Le champs detail est obligatiore',
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 10000,
                title: 'ENREGISTREMENT ÉCHOUÉ',
                text: error.response.data.message,
                color: 'danger',
                position: 'top-right'
              })
              //this.activePrompt4 = false
              //this.activePrompt1 = false
              this.produits_computed = []
              this.intialisePause()
              this.$vs.loading.close()
            })
        } else {

          this.$vs.notify({
            time: 4000,
            title: 'ATTENTION-ERREUR',
            text: 'VALIDATION',
            color: 'warning',
            position: 'top-center'
          })
        }
      })
        
    },
    getAllrecommendedSefetyMeasures () {
      this.$vs.loading()
      this.$http.get('recommendedSefetyMeasures/')
        .then((response) => {
          this.recommendedSefetyMeasures = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllJourneys () {
      this.$vs.loading()
      this.$http.get('journeys/')
        .then((response) => {
          this.journeys = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getOneJourneys (id) {
      this.$vs.loading()
      this.$http.get(`journeys/${id}/`)
        .then((response) => {
          this.journeys = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    
    getAllMissions () {
      this.$vs.loading()
      this.$http.get('missions/')
        .then((response) => {
          this.missions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllstaffs () {
      this.$vs.loading()
      this.$http.get('staffs/')
        .then((response) => {
          this.staffs = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.journeys = this
    this.getAllstaffs()
    this.getAllJourneys()
    this.getAllMissions()
    this.getAllrecommendedSefetyMeasures()

  },
  mounted () {
    this.isMounted = true
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_journey
    this.mission_data_up = window.Planifications.planification_mission_data
    this.mission_id = this.mission_data_up.id
    this.mission_name = this.mission_data_up.poNumber
    if (this.mission_data === 1) {
      this.addNewData()
    } 
    if (this.mission_data === 2) {
      this.ShowData(this.mission_data_up.missionJourney[0])
    } 
    if (this.mission_data === 3) {
      this.editData(this.mission_data_up.missionJourney[0])
    } 
    if (this.mission_data === 4) {
      this.ShowDataTrajet(this.mission_data_up.missionJourney[0])
    } 

    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2

    }
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  